const config = {
    // images_url: 'http://185.78.220.205/plesk-site-preview/leanfactory-web.gr/https/185.78.220.205',
    // basename: '/plesk-site-preview/leanfactory-web.gr/https/185.78.220.205',
    // api_url: 'http://185.78.220.205/plesk-site-preview/leanfactory-web.gr/https/185.78.220.205/public/api/',

    images_url: 'https://login.leanfactory.gr',
    basename: '/',
    api_url: 'https://login.leanfactory.gr/api/',
    // api_url: 'https://local.leanfactory.dev/api/',

    // images_url: 'http://localhost:8080',
    // basename: '/',
    // api_url: 'http://localhost:8080/api/'
}
export default config; //export to config in order to use it in views